var exports = {};

var getChildren = exports.getChildren = function (elem) {
  return elem.children;
};

var getParent = exports.getParent = function (elem) {
  return elem.parent;
};

exports.getSiblings = function (elem) {
  var parent = getParent(elem);
  return parent ? getChildren(parent) : [elem];
};

exports.getAttributeValue = function (elem, name) {
  return elem.attribs && elem.attribs[name];
};

exports.hasAttrib = function (elem, name) {
  return !!elem.attribs && hasOwnProperty.call(elem.attribs, name);
};

exports.getName = function (elem) {
  return elem.name;
};

export default exports;