var exports = {};

exports.removeElement = function (elem) {
  if (elem.prev) elem.prev.next = elem.next;
  if (elem.next) elem.next.prev = elem.prev;

  if (elem.parent) {
    var childs = elem.parent.children;
    childs.splice(childs.lastIndexOf(elem), 1);
  }
};

exports.replaceElement = function (elem, replacement) {
  var prev = replacement.prev = elem.prev;

  if (prev) {
    prev.next = replacement;
  }

  var next = replacement.next = elem.next;

  if (next) {
    next.prev = replacement;
  }

  var parent = replacement.parent = elem.parent;

  if (parent) {
    var childs = parent.children;
    childs[childs.lastIndexOf(elem)] = replacement;
  }
};

exports.appendChild = function (elem, child) {
  child.parent = elem;

  if (elem.children.push(child) !== 1) {
    var sibling = elem.children[elem.children.length - 2];
    sibling.next = child;
    child.prev = sibling;
    child.next = null;
  }
};

exports.append = function (elem, next) {
  var parent = elem.parent,
      currNext = elem.next;
  next.next = currNext;
  next.prev = elem;
  elem.next = next;
  next.parent = parent;

  if (currNext) {
    currNext.prev = next;

    if (parent) {
      var childs = parent.children;
      childs.splice(childs.lastIndexOf(currNext), 0, next);
    }
  } else if (parent) {
    parent.children.push(next);
  }
};

exports.prepend = function (elem, prev) {
  var parent = elem.parent;

  if (parent) {
    var childs = parent.children;
    childs.splice(childs.lastIndexOf(elem), 0, prev);
  }

  if (elem.prev) {
    elem.prev.next = prev;
  }

  prev.parent = parent;
  prev.prev = elem.prev;
  prev.next = elem;
  elem.prev = prev;
};

export default exports;